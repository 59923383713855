export default {
  translations: {
    debtBox: {
      title: 'Carteira de créditos',
      instructions: {
        title: 'Veja créditos a cobrar, gere <strong>relatórios, simulações e boletos</strong>',
        line01: 'Preencha  um ou mais campos para encontrar inscrições de créditos cadastrados na PGM.',
        line02: 'Quanto mais campos com informações, mais refinada será sua busca.',
        line03: 'Dúvidas sobre créditos?',
        link: '<a>Saiba mais aqui</a>',
      },
      tax: {
        selicBlock: 'O acesso ao aplicativo de Dívida Ativa foi bloqueado para atualização da taxa SELIC mensal.',
      },
      search: {
        confirm: 'Consultar',
        clear: 'Limpar consulta',
        moreFilters: 'Expandir filtros',
        lessFilters: 'Menos filtros',
        tags: 'Tags',
        fields: {
          showExtinctDebits: 'Exibir dívidas extintas',
          contributor: {
            label: 'Contribuinte',
            placeholder: 'cpf/cnpj ou nome',
          },
          processNumber: {
            label: 'Processo judicial',
            placeholder: '00000000000',
          },
          inscription: {
            label: 'Inscrição administrativa',
            placeholder: '00 0000-00',
          },
          protestProtocol: {
            label: 'Protocolo de protesto',
            placeholder: '0000000000',
          },
          cda: {
            label: 'CDA',
            placeholder: '00.00.000.000.00',
          },
          installmentNumber: {
            label: 'Número do parcelamento/acordo',
            placeholder: '0000.000',
          },
          origin: {
            label: 'Chave de origem',
            placeholder: '00000',
          },
        },
        receiptTypes: {
          cdaAndRequeriments: ' {{cdas}} CDAs e {{requeriments}} Requerimentos',
          totalBalance: 'Total: {{balance}}',
        },
        footer: {
          total: 'Total',
          selectedCdas: 'CDAs ativas selecionadas',
          totalSelected: '{{number}} CDAs selecionadas',
          selectedItem: '{{ selected }} selecionadas',
          info: 'Serão gerados documentos separados para cada grupo de CDAs.',
          extract: 'Gerar extrato',
          simulation: 'Simular parcelamento',
          negociation: 'Gerar negociação',
          billet: 'Gerar boleto à vista',
        },
      },
      results: {
        address: 'Endereço de cobrança: ',
        active: 'Ativos',
        extinct: 'Extintos',
        groups: {
          totalBalance: 'Total: ',
          totalSelected: 'Total selecionado: ',
        },
        cdasExtract: {
          noResult: 'Sem resultados',
          active: {
            total: 'Saldo devedor geral (A + B + C):',
            opened: 'Em aberto (A):',
            negotiation: 'Em negociação (B):',
            overdue: 'Em atraso (C):',
            lost: 'Negociações perdidas:',
          },
          extinct: {
            total: 'Saldo extinto (A + B + C + D):',
            settled: 'Quitados (A):',
            canceled: 'Cancelados (B):',
            prescribed: 'Prescritos (C):',
            redeemed: 'Remidos (D):',
          },
        },
        inscriptions: {
          emptyNumber: 'Sem número de inscrição',
          openList: 'Expandir todas',
          closeList: 'Recolher todas',
          selectAll: 'Selecionar todas',
          removeAll: 'Desmarcar todas',
          debts: {
            date: 'Exercício',
            mainValue: 'Valor original',
            fines: 'Multa punitiva',
            mainBalance: 'Valor atual',
            finesBalance: 'Multa punitiva atual',
            monetaryCorrection: 'Correção monetária',
            interestValue: 'Juros',
            moratoriumFines: 'Multa moratória',
            feeValue: 'Encargos',
            balance: 'Saldo devedor',
            prescription: 'Prescrição',
            prescriptionHistory: {
              modal: {
                title: 'Histórico de prescrição',
                table: {
                  date: 'Data',
                  action: 'Ação',
                  reason: 'Motivo',
                  description: 'Descrição',
                },
              },
            },
            defaultCalculation: {
              title: 'Padrão de cálculo',
              debtDecription: 'Débito Descrito',
              debtInfo: {
                balance: '<bold>Saldo Devedor</bold> = Valor atual + Multa Punitiva atual + Correção Monetária + Juros + Multa Moratória + Encargos',
                currentBalance: '<bold>Valor atual</bold> = Valor original - Amortização (<italic>pagamentos das parcelas negociadas</italic>)',
                punitiveFine: '<bold>Multa punitiva atual</bold> = Multa punitiva (<italic>informado pelos órgãos de origem</italic>) - Amortização (<italic>pagamento das parcelas negociadas</italic>)',
                monetarieCorrection: '<bold>*Correção monetária</bold> = (Valor atual + Multa punitiva) x fator da correção (%)',
                interests: '<bold>*Juros</bold> = (Valor atual + Multa punitiva atual + Correção monetária) x fator do juros (%)',
                moratoriumFine: '<bold>*Multa Moratória</bold> = Valor atual x fator da multa motarória (%)',
                charges: '<bold>Encargos</bold> = (Valor atual + Multa punitiva atual + Correção Monetária + Juros + Multa moratória) x 10%',
                obs: '<italic>*Fator em porcentagem está na tabela em cada Valor</italic>',
              },
            },
            sefinSituationHistory: {
              title: 'Histórico de situação',
              table: {
                date: 'Data',
                situation: 'Situação',
                protocol: 'Protocolo de recebimento',
                response: 'Resposta SEFIN',
              },
            },
            calculationDefaultInfo: {
              title: 'Padrão de cálculo do débito',
              table: {
                name: 'Nome',
                code: 'Código',
                correction: 'Correção',
                fines: 'Juros',
                moratoriumFine: 'Multa Moratória',
                applicatedTo: 'Aplicável sobre',
              },
            },
          },
        },
        actions: {
          label: 'Ações',
          approveRequirement: 'Aprovar requerimento',
          inscript: 'Inscrever em dívida',
          extract: {
            label: 'Gerar extrato',
            success: 'Sucesso ao baixar extrato.',
            error: 'Erro ao baixar extrato.',
          },
          billet: {
            label: 'Gerar boleto à vista',
            success: 'Sucesso ao baixar boleto à vista.',
            error: 'Erro ao baixar boleto à vista. {{value}}',
          },
          downloadCDA: 'Baixar CDA(s)',
          paymentRequestSimulation: 'Simular negociação',
          paymentRequest: 'Gerar negociação',
          paymentRequestBillet: 'Gerar boleto de vencimento',
          cancelPaymentRequest: 'Cancelar negociação',
          changeState: {
            title: 'Atualizar fase',
            submit: 'Atualizar',
            cda: 'CDA',
            procedure: 'Processo associado',
            state: 'Fase atual',
            newState: 'Nova fase',
            newStatePlaceholder: 'Selecione a nova fase',
            reason: 'Motivo',
            reasonPlaceholder: 'Motivo',
            date: 'Data prescrição',
            observation: 'Justificativa',
            observationPlaceholder: 'Justificativa',
            remove: 'Remover inscrição',
            success: 'Fase alterada com sucesso',
            error: 'Erro ao alterar a fase',
            observations: {
              title: 'Observações:',
              obs1: 'Desjudicialização: Ao realizar "Desjudicialização" as CDAs serão dissociadas dos processos judiciais e os processos serão arquivados.',
              obs2: 'Exigibilidade suspensa: Ao alterar a fase para "Exigibilidade suspensa" o prazo prescricional será suspenso.',
            },
          },
        },
      },
      report: {
        title: 'Gerar relatório',
        full: 'Extrato do contribuinte',
        fullDetailed: 'Extrato do contribuinte detalhado',
        result: 'Extrato do resultado',
        export: 'Planilha das CDAs',
        custom: {
          title: 'Extrato personalizado',
          message: 'O que você deseja no relatório?',
          protested: 'CDAs que contém <strong>protesto</strong>',
          legalAction: 'CDAs que contém <strong>ajuizamento</strong>',
          receiptType: '<strong>Tipo de receita</strong>',
          receiptTypeLabel: 'Selecione receita(s)/tributo(s)',
          currentState: '<strong>Fase atual</strong>',
          currentStateLabel: 'Selecionar fase(s)',
        },
        paymentRequestSimulation: {
          info: 'Gerando simulação. Isso pode levar alguns segundos, por favor, aguarde.',
          error: 'Erro ao gerar simulação.',
        },
        activeNegotiations: 'Negociações ativas',
        error: 'Erro ao gerar relatório',
        info: 'Gerando relatório. Isso pode levar alguns segundos, por favor, aguarde.',
      },
      newNegotiation: {
        title: 'Gerar negociação',
        obs1: 'Obs1. Em havendo a junção de débitos de várias CDAs, parceladas e não parceladas, o novo parcelamento obedecerá à regra da diminuição do número máximo de mensalidades.',
        obs2: 'Obs2. Os valores das parcelas serão reajustados mensalmente de acordo com a lei vigente.',
        obs3: 'Obs3. Será gerado um parcelamento por grupo.',
        submit: 'Realizar negociação',
        nextStep: 'Próxima etapa',
        removeCda: 'Remover CDA',
        generateBillet: 'Gerar boleto',
        instructions: {
          title: 'Gere o boleto da primeira parcela agora.',
          instruction1: 'Lembretes para o contribuinte',
          instruction2: '1. A primeira parcela deve ser paga para efetivar a negociação;',
          instruction3: '2. Neste momento será gerado apenas o boleto inicial para efetivar a negociação;',
          instruction4: '3. Os demais boletos podem ser gerados após a confirmação do pagamento deste.',
        },
        representativeModal: {
          addRepresentative: 'Adicionar requerente',
          title: 'Adicionar requerente',
          form: {
            data: 'Dados',
            address: 'Endereço',
            tooltip: 'Novo requerente',
            title: 'Dados do requerente',
            type: 'Requerente *',
            typePH: 'Escolha tipo do requerente',
            representative: 'Requerente cadastrado',
            representativePh: 'Busque por um requerente cadastrado',
            name: 'Nome',
            namePh: 'Nome do representante',
            rg: 'Rg',
            rgPh: 'Rg do representante',
            cpf: 'CPF',
            cpfPh: 'CPF do representante',
            email: 'E-mail',
            emailPh: 'E-mail do representante',
            phone: 'Telefone',
            phonePh: 'Telefone do representante',
            postalCode: 'CEP',
            postalCodePh: 'CEP do representante',
            street: 'Logradouro',
            streetPh: 'Logradouro do representante',
            streetNumber: 'Número',
            streetNumberPh: 'Ex: 123',
            complement: 'Complemento',
            neighborhood: 'Bairro',
            city: 'Cidade',
            state: 'Estado',
            save: 'Adicionar requerente',
          },
          toast: {
            link: 'Negociação',
            success: 'Representante adicionado com sucesso:',
            error: 'Erro ao adicionar representante.',
          },
        },
        success: 'Negociação gerada com sucesso',
        billetError: 'Erro ao gerar o boleto',
        show: 'Ver negociação',
        modal: {
          selectedInscription: '<bold>{{number}}</bold> CDA selecionada do grupo {{group}}. Total <bold>{{total}}</bold>',
          selectedManyInscriptions: '<bold>{{number}}</bold> CDAs selecionadas do group {{group}}. Total <bold>{{total}}</bold>',
          unselect: 'Desselecionar todas',
          total: 'Total: {{value}}',
          contributor: 'Contribuinte: {{name}} - {{document}}',
          totalGroup: 'Total do grupo: {{value}} em <bold>{{qnt}}x</bold>',
          paymentTitle: 'Simulação',
          simulationPdf: 'Gerar simulação completa',
          simulation: '1. Simular negociação',
          documents: '2. Documentos',
          billets: '3. Boletos',
          minValueInstallment: 'Valor mínimo da parcela: <bold>{{value}}</bold>',
          maxInstallments: 'Máximo de parcelas: <bold>{{value}}</bold>',
          label: 'Em quantas vezes?',
          placeholder: 'Selecione o nº de parcelas',
          documentsTab: {
            label: 'Clique no botão abaixo para gerar o documento',
            action: 'Gerar termo de reconhecimento',
            obs1: 'Obs. 1: Ao gerar o termo você atualizará a data de vencimento de todas as parcelas do(s) débito(s) selecionados.',
            obs2: 'Obs. 2: Será gerado um termo com todos os grupos de negociação.',
            attachTitle: 'Anexar documentos',
            name: 'Arquivo',
            date: 'Data do anexo',
            type: 'Tipo de documento*',
            delete: 'Excluir',
            addFile: 'Adicione múltiplos arquivos aqui',
            addLabel: 'Ou solte-os nessa página',
            requiredDocuments: '<bold>Documentos necessários:</bold> {{value}}',
            selectLabel: 'Selecionar tipo de documento',
            generateTerm: {
              title: 'Aqui está o documento de reconhecimento da dívida 👇',
              text: 'Este documento serve como comprovante da ciência do contribuinte sobre a negociação realizada.',
              button: 'Baixar termo de reconhecimento da dívida',
            },
          },
        },
        negotiation: {
          number: 'Negociação nº ',
          success: 'Negociação gerada com sucesso.',
          cda: 'CDA(s)',
          status: 'Status',
          requestedAt: 'Solicitado em',
          installments: 'Parcelas',
          balance: 'Saldo devedor',
          showMore: 'ver mais',
          showLess: 'ver menos',
        },
      },
      cancelNegotiation: {
        number: 'Negociação nº ',
        reason: 'Motivo',
        reasonText: 'Motivo do cancelamento',
        modalTitle: 'Deseja realmente cancelar negociação?',
        confirm: 'Cancelar',
        close: 'Não',
        text: 'Negociação nº <a href="/payment_request/{{id}}" target="_blank">{{number}}</a> será cancelada.',
        success: 'Negociação cancelada com sucesso.',
        fail: 'Erro ao cancelar negociação.',
      },
    },
    paymentRequest: {
      title: 'Detalhes da negociação',
      data: {
        title: 'Dados do pagamento',
        number: 'Número da negociação',
        contributor: 'Contribuinte',
        inscriptions: 'Inscrições',
        cdas: 'CDAs',
        date: 'Solicitado em',
        status: 'Status',
        balance: 'Saldo devedor',
        paid: 'Total pago principal',
        installments: 'Parcelas pagas',
        tag: {
          active: 'Em aberto',
          extinct: 'Extintas',
        },
      },
      documents: {
        title: 'Documentos',
        file: 'Arquivo',
        date: 'Data do anexo',
        type: 'Tipo de documento',
        attachedDocuments: 'Documentos anexados:',
        hasInstallmentTerm: 'Termo de parcelamento gerado com sucesso: ',
        showDocument: 'ver pdf',
        text: 'Clique no botão abaixo para gerar o documento de reconhecimento da dívida e seu respectivo parcelamento.',
        alert: '*Ao gerar o termo, o contribuinte deverá assinar e não será possível gerar outro termo.',
        confirm: 'Baixar termo de reconhecimento de dívida',
        delete: 'Excluir',
        deleteTooltip: 'Excluir arquivo',
        attachDocument: {
          title: 'Anexar documento',
          subtitle: 'Anexos',
        },
        alertDocuments: 'Documentos pendentes: ',
        confirmAlertTitle: 'Deseja realmente excluir o documento?',
        confirmAlertText: 'O documento será excluído da negociação Nº ',
      },
      installmentHeader: 'Parcelas',
      billets: {
        title: 'Boletos gerados',
        number: 'Parcela',
        id: 'Identificador do DAM/boleto',
        cratedBy: 'Gerado por',
        cratedAt: 'Gerado em',
        pendencies: 'Pendências',
        actions: 'Ações',
        total: 'Total',
        due: 'Vencimento da parcela',
        dueDoc: 'Vencimento do documento',
        paidAt: 'Pago em',
        notPaid: 'não pago',
      },
      paidBillets: {
        title: 'Parcelas quitadas',
        number: 'Parcela',
        id: 'Identificador do DAM/boleto',
        mainValue: 'Valor principal',
        fine: 'Multa punitiva',
        monetaryCorrection: 'Correção monetária',
        interestValue: 'Juros',
        fee: 'Encargos',
        moratoriumFines: 'Multa moratória',
        moratoriumFinesInstallment: 'Multa moratória da parcela',
        discount: 'Desconto',
        total: 'Total',
        dueDate: 'Vencimento do documento',
        installmentDueDate: 'Vencimento da parcela',
        paidAt: 'Pago em',
      },
      unpaidBillets: {
        title: 'Parcelas em aberto',
        selectAll: 'Selecionar todas',
        unselectAll: 'Desmarcar todas',
        number: 'Parcela',
        mainValue: 'Valor principal',
        fine: 'Multa punitiva',
        monetaryCorrection: 'Correção monetária',
        interestValue: 'Juros',
        fee: 'Encargos',
        moratoriumFines: 'Multa moratória',
        moratoriumFinesInstallment: 'Multa moratória da parcela',
        discount: 'Desconto',
        total: 'Total',
        dueDate: 'Vencimento',
        generateBillets: 'Gerar boleto(s)',
        requeriment: {
          alert: {
            title: 'Deseja realmente <br><bold>aprovar requerimento?</bold>',
            subtitle: '<bold>{{inscriptionsLength}} inscrição(ões) </bold> selecionada(s) do grupo "{{inscriptionGroup}}" <br> Total: R$ <strong>{{total}}</strong>',
          },
          toast: 'Requerimento aprovado com sucesso.',
          toastError: 'Erro ao aprovar requerimento.',
        },
        downloadCDA: {
          toast: {
            success: 'Sucesso ao baixar CDA(s)',
            noCDAsFound: 'Não existe(em) documento(s) para download.',
            error: 'Erro ao baixar documento(s)',
          },
        },
        downloadPaymentRequest: {
          toast: {
            success: 'Sucesso ao baixar simulação de negociação.',
            error: 'Erro ao baixar simulação de negociação.',
          },
        },
        inscriptDebt: {
          alert: {
            title: 'Deseja realmente <br><bold>inscrever em dívida?</bold>',
            subtitle: '<bold>{{inscriptionsLength}} inscrição(ões) </bold> selecionada(s) do grupo "{{inscriptionGroup}}" <br> Total: R$ <strong>{{total}}</strong>',
          },
          agreeText: 'Ciente de que ao inscrever o requerimento se torna uma CDA',
          toast: 'Inscrição em dívida realizada com sucesso',
          toastError: 'Erro ao inscrever requerimento em dívida.',
        },
      },
      pendencies: {
        title: 'Pendências',
        number: 'Parcela',
        id: 'Identificador do DAM/boleto',
        total: 'Total',
        dueDate: 'Vencimento',
        type: 'Tipo de pendência',
      },
    },
    inscription: {
      title: 'Certidão de Dívida Ativa',
      generateCDA: 'Baixar CDA',
      state: 'Fase: {{label}}',
      stateHistory: 'Ver histórico de fases',
      data: {
        title: 'Dados da inscrição',
        responsible: 'Responsável',
        legalType: 'Tipo de Pessoa',
        receiptType: 'Tipo de Receita',
        address: 'Endereço',
        fiscalDate: 'Exercício',
        baseDocument: 'Doc. base para Inscrição',
        origin: 'Orgão de origem',
        inscriptionDate: 'Data da Inscrição',
        phase: 'Fase',
        paymentRequest: 'Solicitação de Pagamento',
        legalProvisions: 'Disposições Legais',
        spuNumber: 'Número do SPU',
        documentNumber: 'Número do Documento',
        documentDate: 'Data do Documento',
        sefinSituation: {
          title: 'Situação SEFIN',
          success: 'Situação SEFIN atualizada com sucesso.',
          error: 'Falha ao atualizar situação SEFIN. Por favor, tente novamente mais tarde.',
        },
        updateAddress: {
          label: 'Atualizar endereço',
          success: 'Endereço atualizado com sucesso.',
          error: 'Falha ao atualizar o endereço. Por favor, tente novamente mais tarde.',
        },
        sharedResponsibles: {
          title: 'Corresponsáveis',
          noInfo: 'Sem corresponsáveis',
        },
      },
      phaseHistoryModal: {
        title: 'Histórico de fases',
        table: {
          date: 'Data',
          balance: 'Valor',
          phase: 'Fase',
          individual: 'Colaborador',
          reason: 'Justificativa',
        },
      },
      protestInfoModal: {
        tooltipTitle: 'Ver mais',
        title: 'Informações do protesto',
        registryName: 'Nome do cartório: ',
        address: 'Endereço: ',
        phone: 'Telefone: ',
        protocol: 'Protocolo: ',
        date: 'Data da ocorrência: ',
        sendDate: 'Data de envio: ',
      },
      debts: {
        title: 'Débitos',
      },
      linkedProcedures: {
        title: 'Processos associados',
        number: 'Número',
        flux: 'Fluxo',
        currentSituation: 'Situação atual',
        responsible: 'Responsável atual',
        linkedBy: 'Associado por',
        removeLink: 'Remover associação',
        empty: 'Não há processo associado.',
        action: 'Associar processo',
        dissociateAlert: {
          title: 'Deseja realmente desassociar este processo?',
          subTitle: 'O processo N° <bold>{{value}}</bold> será desassociado desta inscrição.',
        },
        modal: {
          title: 'Associar processo à CDA',
          procedureNumber: 'Número do processo',
          submit: 'Associar',
          success: 'Associado com sucesso',
          error: 'Erro ao associar',
        },
      },
      documents: {
        title: 'Documentos',
        inscriptionsDocuments: 'Documentos da inscrição',
        noDocuments: 'Esta incrição não possui documentos.',
        paymentRequestNumber: 'Negociação nº ',
        showPaymentRequest: 'Ver negociação',
        documentList: {
          title: 'Arquivo',
          date: 'Data do anexo',
          type: 'Tipo do documento',
          attatchedBy: 'Anexado por',
          delete: 'Excluir',
        },
        attachDocument: 'Anexar documentos',
        toast: {
          success: 'Documento(s) anexado(s) com sucesso',
          error: 'Erro ao anexar documento(s)',
          deleteError: 'Erro ao excluir documento da inscrição',
        },
        confirmAlertTitle: 'Deseja realmente excluir o documento?',
        confirmAlertText: 'O documento será excluído da inscrição',
      },
      timeline: {
        title: 'Linha do tempo',
        requestedBy: 'Solicitado por: ',
        requestedAt: 'Solicitado em: <bold>{{value}}</bold>',
        paymentRequest: 'Negociação: <bold>{{value}}</bold>',
        dueDate: 'Vencimento: <bold>{{value}}</bold>',
        paidAt: 'Pago em: <bold>{{value}}</bold>',
        type: '{{type}}: <bold>{{value}}</bold>',
        installment: 'Parcela: <bold>{{value}}</bold>',
        createdBy: 'Gerado por: ',
        createdAt: 'Gerado em: <bold>{{value}}</bold>',
      },
      lostNegotiation: {
        pill: {
          toolTipTittle: 'Ver negociação',
        },
      },
    },
    contributorBox: {
      title: 'Área do contribuinte',
      new: 'Novo contribuinte',
      placeholder: 'Pesquise por nome, CPF ou CNPJ do contribuinte...',
      noOptions: 'Nenhum resultado encontrado',
      instructions: {
        title: '<strong>Cadastre, consulte e edite</strong> dados do contribuinte.',
        line01: 'Digite na busca a informação do contribuinte que você procura.',
        line02: 'Dúvidas sobre a Área do contribuinte?',
        link: '<a>Saiba mais aqui</a>',
      },
      form: {
        title: 'Cadastrar novo contribuinte',
        submit: 'Cadastrar',
        basic: {
          label: 'Dados básicos',
          edit: 'Editar dados do contribuinte',
          name: 'Nome',
          namePlaceholder: 'Nome do contribuinte',
          cpf: 'CPF',
          cnpj: 'CNPJ',
          profession: 'Profissão',
          maritalStatus: 'Estado civil',
          educationalStage: 'Escolaridade',
          gender: 'Gênero',
          birthDate: 'Data de nascimento',
          deathDate: 'Data de óbito',
          update: 'Atualizar dados básicos',
          success: 'Cadastro salvo com sucesso!',
          error: 'Erro ao editar cadastro',
          required: 'Campo obrigatório',
          legalType: {
            label: 'Tipo',
            legalPerson: 'Pessoa jurídica',
            physicalPerson: 'Pessoa física',
          },
        },
        address: {
          label: 'Endereço atual',
          cep: 'CEP',
          cepPlaceholder: '00000-000',
          addressField: 'Endereço',
          addressPlaceholder: 'Nome do logradouro',
          number: 'Número',
          numberPlaceholder: '00',
          neighborhood: 'Bairro',
          neighborhoodPlaceholder: 'Nome do bairro',
          complement: 'Complemento',
          complementPlaceholder: 'Digite o complemento',
          state: 'Estado',
          city: 'Município',
          addressDocument: 'Comprovante de endereço',
          documentButton: 'Adicionar documento',
          documentDate: 'Data do documento',
          datePlaceholder: 'dd/mm/yyyy',
        },
        contact: {
          label: 'Contatos atuais',
          text: 'O contribuinte deve ter pelo menos um contato atual.',
          type: {
            label: 'Tipo de contato',
            placeholder: 'Selecione',
            cellPhone: 'Celular (WhatsApp)',
            cellPhonePlaceholder: '(99) 99999-9999',
            telephone: 'Telefone fixo',
            telephonePlaceholder: '(99) 9999-9999',
            email: 'Email',
            emailPlaceholder: 'emaildocontribuinte@email.com',
          },
          whatsapp: {
            label: 'Esse número é Whatsapp?',
            yes: 'Sim',
            no: 'Não',
          },
        },
        success: 'Contribuinte cadastrado com sucesso',
        error: 'Erro ao cadastrar contribuinte',
      },
      details: {
        label: 'Dados do contribuinte',
        name: 'Nome/Razão social',
        document: 'CPF/CNPJ',
        legalType: {
          label: 'Tipo',
          legalPerson: 'Pessoa Jurídica',
          physicalPerson: 'Pessoa Física',
        },
        address: {
          title: 'Lista de endereços',
          message1: 'Os endereços do contribuinte estão todos listados abaixo por ordem da data do cadastro.',
          message2: 'O endereço atual é considerado o endereço onde contribuinte reside atualmente.',
          message3: 'Para tornar um endereço antigo o atual ou corrigir os dados do atual, clique em "Editar".',
          message4: 'Caso queira adicionar um novo endereço para torna-lo atual, clique em "Atualizar endereço".',
          update: 'Atualizar endereço',
          current: 'Endereço atual',
          old: 'Endereços antigos',
          updateText: 'Preencha os campos. Ao adicionar um novo endereço, ele automaticamente <strong>se tornará o endereço atual do contribuinte.</strong>',
          createdBy: 'Cadastramento',
          cep: 'CEP',
          street: 'Endereço',
          number: 'Número',
          complement: 'Complemento',
          neighborhood: 'Bairro',
          city: 'Município',
          state: 'Estado',
          document: 'Documento',
          documentDate: 'Data do doc',
          noDocument: 'Sem doc.',
          success: 'Novo endereço cadastrado com sucesso',
          error: 'Erro ao cadastrar novo endereço',
          emptyData: 'Sem endereços antigos',
          view: 'Visualizar',
          generateAddressDoc: 'Gerar declaração de endereço',
        },
        contact: {
          title: 'Lista de contatos',
          message1: 'Os contatos do contribuinte estão todos listados abaixo por ordem da data do cadastro.',
          message2: 'Os contatos atuais é considerado contatos que o contribuinte tem acesso e está em uso pelo mesmo.',
          message3: 'Para tornar um contato atual antigo, ou seja, o contribuinte não possui mais acesso, ou corrigir algum dado do contato, clique em "Editar".',
          message4: 'Caso queira adicionar um novo contato, clique em "Adicionar contato".',
          addNew: 'Adicionar contato',
          current: 'Contatos atuais',
          old: 'Contatos antigos',
          updateText: 'Preencha os campos. Ao adicionar um novo contato, ele automaticamente <strong>se tornará um contato atual do contribuinte.</strong>',
          createdBy: 'Cadastro do contato',
          email: 'E-mail',
          cellphone: 'Celular',
          telephone: 'Telefone fixo',
          delete: 'Remover',
          success: 'Novo contato cadastrado com sucesso',
          error: 'Erro ao cadastrar novo contato',
          removeSuccess: 'Contato removido com sucesso',
          removeError: 'Erro ao remover contato',
          emptyData: 'Sem contatos',
          errorEmail: 'Email não pode ficar em branco',
          whatsapp: {
            label: 'Whatsapp?',
            yes: 'Sim',
            no: 'Não',
          },
          type: {
            label: 'Tipo de contato',
            telephone: 'Telefone fixo',
            cellPhone: 'Celular',
            email: 'Email',
          },
        },
        attendance: {
          title: 'Histórico de atendimento',
          message: 'Os históricos de atendimentos estão listados abaixo e são salvos sempre que o contribuinte consulta por seu CPF nos serviços do Portal do Contribuinte.',
          date: 'Data do atendimento',
          place: 'Local de atendimento',
          subject: 'Assunto',
          attendant: 'Atendente',
          emptyData: 'Sem histórico de atendimento',
        },
      },
    },
    reports: {
      custom: {
        subtitle: 'Personalizado',
        resumeInfomation: 'Nesse extrato gerado no dia <bold>{{current_date}}</bold>, consta um saldo devedor total de <highlight>{{balance}}</highlight>, sendo os demonstrativos de valores abaixo exibidos, válidos somente <bold>até o último dia útil do mês de {{current_month}}</bold>. O saldo devedor é a soma dos débitos em aberto + restos a pagar das negociações em andamento.',
        alert: {
          title: 'Atenção',
          subtitle: 'Esse extrato pode conter informações incompletas sobre os débitos existentes em razão da utilização de filtros de pesquisa.',
          message: '<b>Para ter acesso à totalidade dos débitos, utilize o <i>Extrato do contribuinte</i> ou <i>Extrato do contribuinte detalhado</i>.</b>',
        },
        resume: {
          title: 'O QUE ESTÁ INCLUSO NESSE EXTRATO?',
          message: 'Este extrato pode não possuir todos os dados da sua Dívida Ativa, pois ele foi personalizado para exibir apenas:',
          total: '<bold>{{total}} CDAs selecionadas manualmente do tributo {{description}}</bold>',
          observation: 'Não considere este documento como saldo devedor total. Para consultar o total solicite o extrato completo.',
        },
        modal: {
          title: 'QUAIS OS CRITÉRIOS DA PERSONALIZAÇÃO DESSE EXTRATO?',
          message: 'Esse extrato pode não possuir todos os dados da sua Dívida Ativa. Não o considere esse documento como saldo devedor total.',
          criteria: 'Critérios escolhidos desse documento são:',
          protest: 'CDAs que contém <bold>protesto</bold>',
          legalAction: 'CDAs que contém <bold>ajuizamento</bold>',
          state: 'Fases: <bold>{{value}}</bold>',
        },
      },
      inscriptions: {
        table: {
          cda: 'CDA',
          state: 'Fase',
          due_date: 'Exercício',
          judicial_process_number: 'Processo',
          main_balance: 'Valor atual',
          fines_balance: 'Multa Punitiva atual',
          monetary_correction: 'Correção Monetária',
          moratorium_fines: 'Multa Moratória',
          interest_value: 'Juros',
          fee_value: 'Encargos',
          balance: 'Saldo Devedor',
        },
      },
      fullStatement: {
        totalBalance: 'SALDO DEVEDOR TOTAL',
        openDebts: 'EM ABERTO',
        negotiationDebts: 'EM NEGOCIAÇÃO',
        portalLink: 'portal.pgm.fortaleza.ce.gov.br',
        headerAccessTile: 'Acesse',
        title: 'EXTRATO DA DÍVIDA ATIVA',
        subtitle: 'COMPLETO',
        resumeInfomation: 'Este extrato foi gerado na data de <bold>{{current_date}}</bold>, com um saldo devedor total de <highlight>{{balance}}</highlight>, sendo os demonstrativos de valores abaixo exibidos, válidos somente <bold>{{current_month}}</bold>. O saldo devedor é a soma dos débitos em aberto + valor remanescente das negociações em andamento.',
        contributorData: {
          title: 'CONFIRA SEUS DADOS',
          name: '<b>Nome:</b> {{contributorName}}',
          document: '<b>Documento:</b> {{document}}',
          address: '<b>Endereço de correspondência:</b> {{address}}',
        },
        activeDebtInfo: {
          myDebt: 'QUAL É A MINHA DÍVIDA?',
          title: 'DÍVIDA ATIVA: O QUE ISSO QUER DIZER ?',
          content: 'Quer dizer que você possui débitos vencidos e não pagos incluídos na lista de devedores da Procuradoria Geral do Município de Fortaleza',
          subContent: 'Esses débitos são inscritos como <b>Certidão de Dívida Ativa (CDA)</b>, documento que reconhece o débito.',
        },
        debtSummary: {
          cardTitle: 'RESUMO DA DÍVIDA EM ABERTO',
          openDebtsTable: {
            tribute: 'Tributo',
            currentBalance: 'Valor atual',
            currentPunitiveFine: 'Multa punitiva atual',
            accessories: 'Acessórios*',
            monetaryCorrection: 'Correção monetária',
            fees: 'Juros',
            moratoriumFines: 'Multa Moratória',
            charges: 'Encargos',
            outstadingBalance: 'Saldo devedor',
            footerInfo: '*Juros + Correção monetária + Multa moratória + Encargos',
            totalBalance: 'Total do saldo devedor: ',
          },
          negotiationDebtsTable: {
            tribute: 'Tributo',
            negotiatedValue: 'Valor Negociado',
            paidValue: 'Valor quitado',
            overdueA: 'Em atraso (A)',
            overdueB: 'A vencer (B)',
            total: 'Total (A+B)',
            remainingTotal: 'Total remanecesnte',
          },
        },
        receiptTypeInfos: {
          mainCards: {
            title: 'RESUMO GERAL DO {{receiptType}}',
          },
          cards: {
            openTitle: '{{receiptType}} EM ABERTO ({{cdas}})',
            subtitleOpen: 'Lista de Certidões de Dívida Ativa que estão com débitos a negociar',
            negotiationTitle: '{{receiptType}} EM NEGOCIAÇÃO ({{cdas}})',
            subtitleNegotiation: 'Lista de Certidões de Dívida Ativa que estão negociadas em andamento e/ou em atraso.',
            extinctTitle: '{{receiptType}} EXTINTAS ({{cdas}})',
            subtitleExtinct: 'Lista de Certidões de Dívida Ativa que foram quitadas, canceladas, prescritas, remidas, dentre outras.',
          },
          table: {
            header: {
              administrativeInscriptions: 'Ins.Adm: ',
              cdas: 'CDAs: ',
              address: 'Endereço: ',
              total_balence: 'Total: ',
            },
            cda: 'CDA',
            phase: 'Fase',
            exercicie: 'Exercício',
            process: 'Processo',
            currentBalance: 'Valor atual',
            currentPunitiveFine: 'Multa Punitiva atual',
            monetaryCorrection: 'Correção monetária',
            fees: 'Juros',
            moratoriumFines: 'Multa Moratória',
            charges: 'Encargos',
            debitBalance: 'Saldo devedor',
            accessories: 'Acessórios',
          },
        },
      },
      header: {
        created_by: 'Emitido pelo Sistema Ágilis Dívida Ativa da PGM de Fortaleza',
      },
      footer: {
        address: 'Avenida Dom Luís, 807, Edifício Etevaldo Nogueira, Meireles. CEP 60160-230',
        phone: '(85) 99226-9903',
        site: 'portal.pgm.fortaleza.ce.gov.br',
      },
      paymentRequestSimulate: {
        label: 'SIMULAÇÃO DE PARCELAS',
        title: 'Simulação de negociação',
        observation: 'Atenção: os valores das parcelas serão atualizados mensalmente pela taxa correspondente ao tributo, a exemplo da SELIC, conforme os Arts. 45 e 62, do Decreto n° 13.601/2015.',
        description: 'Emitido pelo Sistema Ágilis Dívida Ativa da PGM de Fortaleza dia {{currentDate}} sendo válido até {{lastDayOfMonth}}.',
        negotiationDescription: 'Negocie e quite suas dívidas com o município em alguns cliques!',
        infoMessage: 'Prezado(a)',
        infoDescription: 'Esta simulação foi gerada na data de <bold>{{current_date}}</bold>, com o saldo devedor total de <bold>{{balance}}</bold>. Os valores serão atualizados mensalmente com juros, multa de mora, encargos e correção monetária. Não perca tempo, aproveite para negociar agora.',
        cards: {
          contributor: {
            title: 'Confira seus dados',
            name: 'Nome',
            document: 'Documento',
            address: 'Endereço de correspondência',
          },
          cdas: {
            title: 'CDAs SELECIONADAS PARA SIMULAÇão',
            balance: 'SALDO DEVEDOR',
            balanceDescription: '*Valor sem os juros do parcelamento',
            totals: '<bold>{{selectedInscriptionsCount}} de {{inscriptionsCount}} CDAs</bold> de {{receiptTypeDescription}} selecionadas',
          },
          knowMore: {
            title: 'SAIBA MAIS SOBRE NEGOCIAÇÃO',
            lossNegotiation: 'Consequências da perda da negociação',
            lossNegotiationDescription: 'Além da cobrança de correção monetária e juros sobre a dívida, o número de parcelas do seu parcelamento original será reduzido.',
            minimumInstallments: 'Valores mínimos das parcelas',
            minimumInstallmentsCpf: 'Pessoa física: R$ 75,00',
            minimumInstallmentsCnpj: 'Pessoa jurídica: R$ 350,00',
            lossNegotiationConditions: 'Condições de perda da negociação',
            lossNegotiationConditionsDescription: 'Perda ocorre quando o contribuinte deixa de pagar 3 parcelas seguidas ou alternadas, qualquer parcela com mais de 90 dias de atraso, ou se houver saldo devedor após o vencimento da última parcela.',
          },
          list: {
            title: 'LISTA DE CDAs SELECIONADAS PARA ESSA SIMULAÇÃO ({{totalInscriptions}}) CDAs',
            total: '{{totalInscriptions}} CDAs',
          },
        },
        avoidInterest: 'Evite juros nas parcelas.',
        payInCash: 'Economize pagando à vista.',
        table: {
          numberKey: 'Nº DE PARCELAS',
          fistInstallmentKey: '1º PARCELA',
          othersInstallmentsKey: 'DEMAIS PARCELAS',
          interest: 'VOCÊ PAGARÁ DE JUROS*',
        },
        alert: 'A negociação sofreu restrição no quantitativo de parcelas em virtude de negociações anteriores perdidas.',
      },
    },
  },
};
